















import Vue from 'vue'
import CheckinToggle from '@/components/CheckinToggle.vue'
import ModalCheckinCode from '@/components/ModalCheckinCode.vue'

export default Vue.extend({
	name: 'MainFooter',
	components: {
		CheckinToggle,
		ModalCheckinCode,
	},
	data: () : {
		codeToggle: boolean
	} => ({
		codeToggle: false
	}),
})
