








import Vue from 'vue'
import { Store } from 'vuex'
import { Route } from 'vue-router'

import Card from '@/components/Card.vue'
import ListProfiles from '@/components/ListProfiles.vue'

export default Vue.extend({
	name: 'PartialCheckins',
	components: {
		Card,
		ListProfiles,
	},
	mounted() {
		this.$store.dispatch('checkin/loadCheckins')
	},
	data: () : {
		query: string
	} => ({
		query: ''
	}),
 	computed: {
		checkins(): Collection[] {
			return this.$store.getters['checkin/checkins']
		}
	},
})
