







import Vue from 'vue'
import qrcode from 'qrcode-generator'

import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'CheckinCode',
	components: {
		LoadingSpinner,
	},
	props: {
		data: String
	},
	computed: {
		qrcode(): string {
			if (!this.data) return ''
			const typeNumber = 0
			const errorCorrectionLevel = 'L'
			let tag: string = ''

			try {
				const qr = qrcode(typeNumber, errorCorrectionLevel)
				qr.addData(this.data)
				qr.make()
				tag = qr.createImgTag(10, 0)
			} catch (error) {
				console.error(error)
				tag = 'CODE CAN NOT BE GENERATED'
			}

			return tag
		}
	}

})
