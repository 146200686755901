









import isAfter from 'date-fns/isAfter'
import Vue from 'vue'
import Modal from '@/components/Modal.vue'
import ModalTransfer from '@/components/ModalTransfer.vue'

export default Vue.extend({
	name: 'ViewMain',
	components: {
		Modal,
		ModalTransfer,
	},
	computed: {
		logoutWarning() {
			return this.$store.getters['user/logoutWarning']
		},
		user() {
			return this.$store.getters['user/user']
		},
		warningTime() {
			return this.$store.getters['user/warningTime']
		},
		logoutTime() {
			return this.$store.getters['user/logoutTime']
		},
	},
	created() {
		window.addEventListener('smoothScroll', this.updateLogoutTimer)
		document.addEventListener('visibilitychange', this.timerCheck)
	},
	destroyed() {
		window.removeEventListener('smoothscroll', this.updateLogoutTimer)
		document.removeEventListener('visibilitychange', this.timerCheck)
	},
	mounted() {
		this.$store.dispatch('transfer/watchOpen')

		Notification.requestPermission().then(res => {
			this.$store.dispatch('notifications/enableNotifications')
		})

		if (this.user.clientId) {
			this.$store.dispatch('user/loadClient', this.user.clientId)
		}
	},
	methods: {
		updateLogoutTimer() {
			this.$store.dispatch('user/resetLogoutTimer')
		},
		notificationClicked(evt: any) {
			console.log('main.clicked', evt)
		},
		timerCheck() {
			if (document.visibilityState == 'visible') {
				const now = (new Date())

				if (isAfter(now, this.logoutTime)) {
					this.$store.dispatch('misc/setError', `Your account has been logged out due to inactivity.`)
					this.$store.dispatch('user/logout')
					return
				}

				if (isAfter(now, this.warningTime)) {
					this.$confirm(
						`Your account has been idle. You will automatically be signed out soon.`,
						() => {
							this.$store.dispatch('user/autoLogout')
						}, {
							title: `Sign Out Warning`,
							acceptLabel: `Stay Signed In`,
							cancelLabel: `Sign Out`,
							cancelAction: () => {
								this.$store.dispatch(`user/logout`)
							},
							id: `signoutWarning`,
						}
					)
				}
			}
		},
	},
	watch: {
		$route() {
			this.$store.dispatch('user/resetLogoutTimer')
		},
		logoutWarning(newVal: any, oldVal: any): void {
			if (newVal && newVal != oldVal) {
				this.$confirm(
					`Your account has been idle. You will automatically be signed out soon.`,
					() => {
						this.$store.dispatch('user/autoLogout')
					}, {
						title: `Sign Out Warning`,
						acceptLabel: `Stay Signed In`,
						cancelLabel: `Sign Out`,
						cancelAction: () => {
							this.$store.dispatch(`user/logout`)
						},
						id: `signoutWarning`,
					}
				)

				try {
					if (!document.hasFocus()) {
						this.$store.dispatch('notifications/sendNotification', {
							title: `Sign Out Warning!`,
							options: {
								body: `Your account has been idle. You will automaticelly be signed out soon.`,
								icon: `${process.env.BASE_URL}img/icons/android-chrome-512x512.png`,
							},
						})
					}

				} catch (error) {
					console.log(`Notifications not enabled.`, error)
				}

				self.addEventListener('notificationclick', (evt: any) => {
					switch (evt.action) {
						case 'sign-in':
							this.$store.dispatch('user/autoLogout')
							window.focus()
							break;
						case 'view':
							window.focus()
							break
						default:
							break
					}
				})
			}
		},
		user(newVal: Collection): void {
			if (!newVal) {
				this.$router.push({ name: 'login' })
				try {
					this.$confirmClose({ id: `signoutWarning` })
				} catch (error) { }
			}
		},
	}
})
