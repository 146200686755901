



















import Vue from 'vue'

export default Vue.extend({
	name: 'List',
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
		noHover: Boolean,
	},
	computed: {
		hasHeader(): boolean {
			return Boolean(this.$slots.header)
		},
		hasFooter(): boolean {
			return Boolean(this.$slots.footer)
		}
	}
})
