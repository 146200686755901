













import Vue from 'vue'

export default Vue.extend({
	name: 'MenuDrChrono',
	computed: {
		authToken(): string {
			return this.$store.getters['drchrono/token']
		},
		link(): string {
			let url: string = ''
			const provider = this.$store.getters['user/provider']
			const accountLink = this.$router.resolve({ name: 'account' }).href
			const { origin, } = window.location
			const redirectUrl = [origin,accountLink].join('')

			if (provider) {
				switch( provider.key) {
					case 'drchrono':
						url = `https://drchrono.com/o/authorize/?redirect_uri=${redirectUrl}&response_type=code&client_id=${provider.client_id}&scope=patients:read%20patients:write%20clinical:read%20clinical:write%20user:read%20user:write%20billing:patient-payment:read%20billing:patient-payment:write%20billing:read%20billing:write`
						break
				}
			}

			if (this.authToken) {
				url = accountLink
			}
			return url
		},
	},
	methods: {
		connect() {

		},
	},
})
