



































































import Vue from 'vue'
import Fuse from 'fuse.js'
import { router } from '@/router'
import { debounce } from '@/lib/utils'
import { CheckinStatus } from '@/types/types'

import InputText from '@/components/InputText.vue'
import List from '@/components/List.vue'
import TabBar from '@/components/TabBar.vue'
import StatusChip from '@/components/StatusChip.vue'

export default Vue.extend({
	name: 'ListProfiles',
	components: {
		InputText,
		List,
		StatusChip,
		TabBar,
	},
	props: {
		profiles: Array,
	},
	data: () : {
		columns: Collection,
		// items: Collection[],
		statuses: Collection,
		keyword?: string,
		query: string,
	} => {
		return {
			columns: {
				photo: 'Photo',
				name: 'Name',
				dob: 'Birthdate',
				createdAt: 'Check-In Time',
				status: 'Status',
			},
			// items: [
			// 	{
			// 		id: 1,
			// 		photo: null,
			// 		name: 'Thomas Maloney',
			// 		dob: '09/19/1982',
			// 		createdAt: (new Date()).getTime() - 10000,
			// 		status: 'accepted',
			// 	},
			// ],
			statuses: [
				{
					action() {
						router.push({ query: { status: null }})
					},
					label: 'All',
				},
				{
					action() {
						router.push({ query: {status: CheckinStatus.New }})
					},
					label: 'New',
				},
				{
					action() {
						router.push({ query: {status: CheckinStatus.Accepted }})
					},
					label: 'Accepted',
				},
				{
					action() {
						router.push({ query: { status: CheckinStatus.Transferred }})
					},
					label: 'Transferred',
				},
			],
			keyword: undefined,
			query: '',
		}
	},
	computed: {
		items(): Collection[] {
			return this.profiles as Collection[]
		},
		queryResults(): Collection[] {
			let checkins = this.items || []
			if (!checkins.length) return checkins
			if (this.$route.query.status) {
				checkins = checkins.filter(r => r.status === this.$route.query.status)
			}
			if (!this.query) return checkins.sort((a: Collection, b: Collection) => a.createdAt - b.createdAt)

			const fuseOptions = {
				threshold: 0.2,
				includeMatches: true,
				findAllMatches: true,
				includeScore: true,
				tokenize: true,
				keys: [
					'firstName',
					'lastName',
					'email',
				],
			}

			const fuse = new Fuse(checkins, fuseOptions)
			const result = fuse.search(this.query)
			result.sort((a, b) => a.score - b.score)
			const results = (result as Array<Collection>).map((res: Collection) => res.item)
			return results
		},
	},
	methods: {
		itemLink(item: Collection): object {
			return {
				name: 'checkin',
				params: {
					id: item.id,
				},
			}
		},
		setKeyword: debounce(function(this: any) {
			if (this.keyword.length > 2 || this.keyword.length === 0) {
				this.$set(this, 'query', this.keyword)
			}
		}, 500),
		setStatus(idx: number) {
			this.statuses[idx].action()
		},
		bgImg(src: string) {
			if (!src) return ''
			return `url(data:image/jpg;base64,${src})`
		},
	},
	filters: {
		initials(val: string, count: number = 2): string {
			let words: string[] = val.split(' ')
			let initials: string = ''
			for (let i = 0; i < Math.min(words.length, count); i++) {
				initials += words[i].charAt(0)
			}
			return initials
		}
	},
})
