
























import Vue from 'vue'
import { MDCMenu } from '@material/menu'
import DrChronoMenu from '@/components/providers/DrChronoMenu.vue'

export default Vue.extend({
	name: 'UserMenu',
	components: {
		DrChronoMenu,
	},
	computed: {
		provider(): Collection {
			return this.$store.getters['user/provider']
		},
	},
	methods: {
		logout(): void {
			this.$confirm(`Are you sure you want log out?`, () => {
				this.$store.dispatch('user/logout')
			})
		}
	}
})
