














import Vue from 'vue'
import CheckinCode from '@/components/CheckinCode.vue'
import LoadingBar from '@/components/LoadingBar.vue'
import Modal from '@/components/Modal.vue'

export default Vue.extend({
	name: 'ModalCheckinCode',
	components: {
		CheckinCode,
		LoadingBar,
		Modal,
	},
	props: {
		open: Boolean
	},
	computed: {
		location() {
			const profile = this.$store.getters['user/profile']
			if (!profile || !profile.location) return

			let location: string = ''
			try {
				location = `MEBKM:TITLE:${profile.location.name}|${profile.location.address};URL:https://getqp.app/o/${profile.location.id};;`
			} catch (error) {
				console.error(error)
			}

			return location
		}
	},
})
