













import Vue from 'vue'
import LoadingBar from '@/components/LoadingBar.vue'
import UserMenu from '@/components/UserMenu.vue'

export default Vue.extend({
	name: 'MainHeader',
	components: {
		LoadingBar,
		UserMenu,
	},
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		provider() {
			return this.$store.getters['user/provider']
		},
	},
})
