
























import Vue from 'vue'
import { MDCSwitch } from '@material/switch'
import { v4 } from 'uuid'

export default Vue.extend({
	name: 'InputSwitch',
	props: {
		id: {
			type: String,
			default: v4(),
		},
		label: String,
		name: String,
		disabled: Boolean,
		value: Boolean,
	},
	computed: {
		attrs(): Record<string, string> {
			return this.$attrs
		},
		listeners(): Record<string, Function> {
			return {
				...this.$listeners,
				input: (event: Event) => this.input(event),
			}
		},
		checked() {
			return Boolean(this.value)
		},
	},
	data: () : {
		switch: MDCSwitch | any
	} => {
		return {
			switch: undefined,
		}
	},
	mounted() {
		const switchEl = this.$el.querySelector('.mdc-switch')
		if (switchEl) {
			this.switch = new MDCSwitch(switchEl)
		}
	},
	methods: {
		input(event: Event): void {
			if (event && event.target) {
				const target = event.target as HTMLInputElement
				this.$emit('input', target.checked)
			}
		}
	},
	inheritAttrs: false,
	watch: {
		value (newVal: boolean, oldVal: boolean | null): void {
			this.switch.checked = newVal
		},
	},
})
