























import Vue from 'vue'
import LoadingBar from '@/components/LoadingBar.vue'
import Modal from '@/components/Modal.vue'
import { TransferStatus } from '@/types/types'

export default Vue.extend({
	name: 'ModalTransfer',
	components: {
		LoadingBar,
		Modal,
	},
	data: () : {
		// sticky: boolean,
		TransferStatus: number | null,
	} => ({
		// sticky: true,
		TransferStatus: TransferStatus.Canceled,
	}),
	computed: {
		status(): TransferStatus {
			return this.$store.getters['transfer/status']
		},
		statusText(): string {
			const transferStatus = this.$store.getters['transfer/status']

			let status: string = ''
			switch( transferStatus ) {
				case TransferStatus.Incoming:
					status = 'Patient Check-In Incoming...'
					break
				case TransferStatus.Connecting:
					status = 'Patient Check-In Connecting...'
					break
				case TransferStatus.Fail:
					status = 'Patient Check-In FAILED! Please tell patient to try again.'
					break
				case TransferStatus.Transferring:
					status = 'Patient Check-In Transferring...'
					break
				case TransferStatus.Transferred:
					status = 'Patient Check-In Transferred'
					break
				case TransferStatus.Success:
					status = 'Patient Check-In SUCCESS!'
					break
				case TransferStatus.Canceled:
					status = 'Patient Check-In Cancelled'
					break
			}
			return status
		},
		open(): boolean {
			return Boolean(this.status)
		},
		sticky() {
			const transferStatus = this.$store.getters['transfer/status']

			let sticky: boolean = true
			switch( transferStatus ) {
				case TransferStatus.Fail:
				case TransferStatus.Success:
				case TransferStatus.Canceled:
					sticky = false
					break
			}

			return sticky
		},
	},
	methods: {
		cancel(): void {
			this.$store.dispatch('transfer/cancelTransfer')
		}
	}
})
