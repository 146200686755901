






import Vue from 'vue'
import InputSwitch from '@/components/InputSwitch.vue'

export default Vue.extend({
	name: 'CheckinToggle',
	components: {
		InputSwitch,
	},
	data: () : {
		openToggle: boolean
	} => ({
		openToggle: false,
	}),
	computed: {
		open(): boolean {
			return this.$store.getters['transfer/open']
		},
		user(): Collection {
			return this.$store.getters['user/user']
		},
	},
	mounted() {
		this.$store.dispatch('transfer/setAccepting', this.user)
	},
	methods: {
		watch() {
			this.$store.dispatch('transfer/setAccepting', this.user)
		},
		unwatch() {
			this.$store.dispatch('transfer/unsetAccepting', this.user)
		},
	},
	watch: {
		openToggle (newVal: boolean, oldVal: boolean | null): void {
			if (newVal) {
				this.watch()
			} else {
				this.unwatch()
			}
		},
		open (newVal: boolean, oldVal: boolean | null): void {
			this.openToggle = newVal
		},
	},
})
